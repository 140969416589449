import React from 'react';

//import components
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { themeCustom, CustomBox } from './styles';

const HeadText = () => {
  return (
    <div>
        <CustomBox>
          <ThemeProvider theme={themeCustom}>
            <Typography variant="h1" align="center">TERAPI KETOK</Typography>
            <Typography variant="h2" align="center" sx={{ marginTop: '0.5rem', marginBottom: '2rem' }}> Mr. Kevin</Typography>
            <Typography variant="body1" align="center">Garden Hous Grand Wisata</Typography>
            <Typography variant="body1" align="center">blok BG1 no.33 Lambangjaya</Typography>
            {/* <Typography variant="body1" align="center">Tambun</Typography> */}
            <Typography variant="body1" align="center">Tambun Selatan</Typography>
            <Typography variant="body1" align="center">Bekasi 17510</Typography>
          </ThemeProvider>
        </CustomBox>
    </div>
  )
}

export default HeadText